export const OpeningHoursData = [
  {
    day: 'Maandag',
    index: 1,
    morning: 'Gesloten',
    afternoon: 'Gesloten',
  },
  {
    day: 'Dinsdag',
    index: 2,
    morning: '09:00 - 12:30',
    afternoon: '13:30 - 17:00',
  },
  {
    day: 'Woensdag',
    index: 3,
    morning: '09:00 - 12:30',
    afternoon: '13:30 - 17:00',
  },
  {
    day: 'Donderdag',
    index: 4,
    morning: '09:00 - 12:30',
    afternoon: '13:30 - 17:00',
  },
  {
    day: 'Vrijdag',
    index: 5,
    morning: '09:00 - 12:30',
    afternoon: '13:30 - 17:00',
  },
  {
    day: 'Zaterdag',
    index: 6,
    morning: '09:30 - 12:30',
    afternoon: 'Gesloten',
  },
  {
    day: 'Zondag',
    index: 0,
    morning: 'Gesloten',
    afternoon: 'Gesloten',
  },
]

export const OpeningHourscolumns = [
  {
    title: 'Dag',
    dataIndex: 'day',
    key: 'day',
    width: 200,
  },
  {
    title: 'ochtend',
    dataIndex: 'morning',
    key: 'morning',
  },
  {
    title: 'Middag',
    dataIndex: 'afternoon',
    key: 'afternoon',
  }
];
